<template>
  <div>
      <slot></slot>
  </div>
</template>

<script>
export default {
    /* HD++ */
}
</script>

<style lang="scss">
.infolettre{

  @apply relative;  
  font-family: "Fira Sans", sans-serif;

  #fields-infolettre-courriel-wrap{
    @apply m-0 mb-1;
    font-family: "Fira Sans", sans-serif; 
  }

  #fields-infolettre-autorisation-wrap{
    @apply m-0 mt-1;
    font-family: "Fira Sans", sans-serif; 
  }

  #fields-infolettre-courriel-wrap{
    padding-right:108px;    
   
   
   
  }

  #fields-infolettre-courriel{
    @apply text-base block w-full text-tertiary appearance-none bg-transparent border-0 border-b-2 border-primary shadow-none rounded-none;        
    box-shadow: none;
    font-family: "Fira Sans", sans-serif;
    margin-bottom:10px;
    &::placeholder{
      @apply text-secondary;
    }

    @screen sm{
      @apply text-lg;
    }
  }

  .fui-input.fui-error:focus, .fui-select.fui-error:focus {
    box-shadow: none;    
  }

  .fui-checkbox input:checked + label::before, .fui-radio input:checked + label::before{
    @apply bg-tertiary border-tertiary;    
  }

  .fui-submit{
    @apply absolute bg-tertiary border-tertiary text-sm rounded-full px-3 py-2 mt-2 font-bold;
    right:0px;
    top:-2px;
    font-family: "Fira Sans", sans-serif;
    &:hover{
      @apply bg-secondary border-secondary;
    }
  }

  .fui-alert-error {
    @apply hidden;
  }

  .fui-error-message{
    @apply mt-0 text-danger font-bold;
    font-family: "Fira Sans", sans-serif;
  }

  .fui-alert-success{
    @apply mb-0 mt-4 text-green-600 p-0 font-bold text-lg;
    background:none;  
    font-family: "Fira Sans", sans-serif;
  }

  .fui-checkbox-label{
    @apply text-gray-500;
  }
  
}
</style>