<template>
    <div class="my-12">
        <svg width="102" height="5">
            <rect width="102" height="5" style="fill:var(--primary);" />
        </svg> 
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>