<template>
  <p>{{ texte }}</p>
</template>

<script>
export default {
    data(){
        return{
            texte: 'ALLOOOOOO'
        }
    }
}
</script>

<style>

</style>