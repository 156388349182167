<template>
    <div id="malartic-fc">                
        <FullCalendar :options="calendarOptions" ref="cal" />
    </div>
</template>

<script>

const axios = require('axios');

import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue'
import listPlugin from '@fullcalendar/list';
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import frLocale from '@fullcalendar/core/locales/fr';
import moment from 'moment'


export default {
    props: {
        year: {
            type: String,
            default: null,
        },
        month: {
            type: String,
            default: null,
        },
    },
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    data() {
        return {
            calendarOptions: {
                plugins: [ dayGridPlugin, interactionPlugin, listPlugin ],
                initialDate: this.year+"-"+this.month+"-01",
                initialView: 'dayGridMonth',
                timeZone: 'UTC',
                locale: frLocale,
                //height: "auto",
                showNonCurrentDates: false,
                firstDay:0,

                buttonText: {
                    listYear: 'Année'
                },
                             
                
                headerToolbar: {
                    left: 'dayGridMonth,listYear',
                    center: 'title',
                    right: 'prev,next'
                },                
                
                //eventDisplay: 'block',
                //eventContent: this.eventContent,
                //moreLinkContent: this.moreLinkContent,
                moreLinkClick: this.moreLinkClick,
                selectable: false,

                //dayCellContent: this.dayCellContent,

                events: this.getEvents,
                views: {
                    dayGridMonth: { // name of view
                        
                        dayHeaderFormat: {
                            weekday: 'narrow'
                        },
                        dayMaxEvents: 4,
                        
                        // other view-specific options here
                    },
                    listWeek: {
                        title: 'test'
                    }

                }
            }
        }
    },
    methods:{
        eventContent(arg){            
            return {
                html: '<i class="fas fa-circle inline-block" style="color:'+ arg.backgroundColor+'"></i>'
            }
        },
       
        moreLinkContent(){
            return '+'
        },
        moreLinkClick(info){
            console.log(info)
        },
        getEvents(info, callback) {

           // console.log(info)

            var data = {
                rangeStart: new Date(info.start).toISOString(),
                rangeEnd: new Date(info.end).toISOString(),
                CRAFT_CSRF_TOKEN : window.csrfToken,
            };

            axios.post('/actions/malartic-module/calendrier/get-events', data)
            .then((response) => {

                var eventList = response.data;

                for (var i = 0; i < eventList.length; i++) {
                    var event = eventList[i];
                    if (event.allDay) {
                      eventList[i].end = moment(event.end).add(2, 's').utc().format();
                    }       
                    event.url = event.url +'/'+moment(event.start).format('YYYY')+'/'+moment(event.start).format('MM')+'/'+moment(event.start).format('DD')
                    event.startEditable=false;
                    
                  }

                 //console.log(eventList)

                  callback(eventList);
            })
            .catch((error) => {
                console.error(error)
            })

            window.dispatchEvent(new Event('resize'));

            
        }
    },
    mounted(){
        //console.log(this.$refs.cal)
        //this.$refs.cal.gotoDate(this.year+'-'+this.month+'-01')
        window.dispatchEvent(new Event('resize'));
    }
}
</script>

<style lang="scss">
#malartic-fc{

.fc .fc-toolbar.fc-header-toolbar{
  @apply mb-0;
  @apply text-white;
  @apply bg-primary;
  @apply h-12;
}

.fc .fc-toolbar-title{
  @apply text-lg;
  @apply capitalize;
  @apply font-bold;
}

.fc .fc-button-primary{
  @apply bg-transparent;
  @apply border-none;  
}

.fc .fc-scrollgrid-section-sticky > *{
  @apply bg-transparent;
}



.fc .fc-daygrid-body-natural .fc-daygrid-day-events{
  @apply mb-0;
}

.fc .fc-scrollgrid{  
  @apply border-none;
 
}

.fc .fc-daygrid-day.fc-day-today{
  background-color: darken(#ededed, 5%);
}

.fc .fc-daygrid-day-events{
 
}

.fc-bgevent {
  opacity: 1;
}

}
</style>