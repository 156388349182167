<template>
    <form class=" w-full flex justify-between items-center space-x-2" :action="url">
        <div class="flex-grow">                        
            <div class="w-full border-b-2 focus-within:border-none">
                <input ref="searchbar" data-lpignore="true" allow-1password="no" autocomplete="off" type="search" name="q" aria-label="Recherche" placeholder="" class="sbox px-0 py-1 md:py-auto text-md md:text-xl block w-full text-white appearance-none focus:outline-none bg-transparent placeholder-secondary" />                            
            </div>
            

        </div>
        <div>
            <button type="submit" class="inline-flex items-center p-1 md:p-3 border border-transparent rounded-full shadow-sm text-white bg-secondary hover:bg-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
            <!-- Heroicon name: search -->                        
            <svg class="w-4 h-4 md:w-6 md:h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
            </button>
        </div>
    </form>
</template>

<script>
import Typed from 'typed.js';

export default {
    data(){
        return {
            typed: null,
        }
    },
    props: {
        url: String
    },
    mounted(){
        this.typed = new Typed('.sbox', {
            strings: ['Que cherchez-vous?','Services municipaux', 'Compte de taxe', 'Emplois','Calendrier'],
            typeSpeed: 40,
            backSpeed: 10,            
            backDelay: 2000,
            attr: 'placeholder',           
            loop: true
        });

    }, 
    destroyed(){
        if (this.typed){
            this.typed.destroy();
        }
        
    },
    created(){
        this.$on('focus', () => {
            this.$refs.searchbar.focus();
        });
    },
    beforeDestroy(){
        this.$off('focus');
    }
}
</script>

<style>

</style>