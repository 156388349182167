<template>
    
    <div class="flex mr-2 md:mx-8">
      <transition
      enter-active-class="transition ease-out duration-100 transform"
      enter-class="opacity-0 scale-95"
      enter-to-class="opacity-100 scale-100"
      leave-active-class="transition ease-in duration-75 transform"
      leave-class="opacity-100 scale-100"
      leave-to-class="opacity-0 scale-95"
      class="flex"
      mode="out-in">
        <div v-if="!isOpen" class="flex items-center space-x-2" key="sclose">
            <div class="hidden lg:block text-white text-md uppercase mr-4">Suivez-nous</div>

            <a href="https://www.facebook.com/VilledeMalartic" target="_blank" class="text-primary bg-white hover:bg-secondary rounded-full p-1">
                <span class="sr-only">Facebook</span>        
                <div class="h-5 w-5 text-center">              
                <i class="fab fa-facebook-f"></i>
                </div>
            </a>      

            <a href="https://www.instagram.com/villedemalartic/" target="_blank" class="text-primary bg-white hover:bg-secondary rounded-full p-1">
            <span class="sr-only">Instagram</span>                
            <div class="h-5 w-5 text-center">              
                <i class="fab fa-instagram"></i>
                </div>
            </a>      

            <a href="https://www.linkedin.com/company/ville-de-malartic/" target="_blank" class="text-primary bg-white hover:bg-secondary rounded-full p-1">
            <span class="sr-only">LinkedIn</span>                
            <div class="h-5 w-5 text-center">              
                <i class="fab fa-linkedin-in"></i>
                </div>
            </a>

            <div class="pl-2 md:pl-4 text-white text-md uppercase mr-4 flex justify-between items-center space-x-2 cursor-pointer hover:text-secondary" @click="open()">
                <span class="hidden lg:block ">Recherche</span>
                <div>
                    <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path></svg>
                </div>
            </div>
        </div>

        <div v-else class="flex items-center space-x-2" key="sopen">
            <search-type ref="searchbar" url="/recherche"></search-type> 
            
            <button @click="close()" type="button" class="inline-flex items-center border border-transparent rounded-full shadow-sm text-white hover:bg-tertiary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
            <!-- Heroicon name: x -->                        
            <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
            
            </button>
        </div>
      </transition>
    </div>
  
</template>

<script>
export default {
    data(){
        return {
            search: '',
            isOpen: false,
        }
    },
    methods:{
        open(){            
            this.isOpen=true;
            setTimeout(() => {
                this.$refs.searchbar.$emit('focus')
            }, 300)
        },
        close(){
            
            this.isOpen=false;
        }
    }
}
</script>

<style>

</style>