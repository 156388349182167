import Vue from "vue";

import Components from "./components";
import "./app.scss";
//import "./app.css";

import "@fortawesome/fontawesome-free/js/all";

import ClickOutside from 'vue-click-outside'

import VueSocialSharing from 'vue-social-sharing'
Vue.use(VueSocialSharing);

import * as VueGoogleMaps from 'vue2-google-maps'
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCbZyZp_Ye57x33Xdg-bfbPL00MCJUZfAs',
    //libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    //// If you want to set the version, you can do so:
    // v: '3.26',
  },
  installComponents: true
})

const moment = require('moment')
require('moment/locale/fr')
 
Vue.use(require('vue-moment'), {
    moment
})

Vue.config.productionTip = false;

new Vue({
  el: "#app",
  delimiters: ["${", "}"],
  data(){
    return {
      isloaded: false,
      isOpen: false,
    }
  }, 
  mounted(){
    this.isloaded=true;
    // prevent click outside event with popupItem.
    this.popupItem = this.$refs.menu
    this.$refs.menu.classList.remove('hidden')
    this.$refs.accesrapide.classList.remove('hidden')
  },
  directives: {
    ClickOutside
  },
  components:{
    
  },
  methods:{
    hide(){
      this.isOpen=false;
    }
  }
});