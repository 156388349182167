<template>
  

<div class="fixed transform transition ease-in-out duration-500 sm:duration-700" style="z-index:9;top:20%;right:0;height:250px" :class="isOpen ? 'translate-x-menuopen' : 'translate-x-menuclosed'">
  
        <div class="bg-white shadow flex justify-between h-full">
        
        <div class="bg-tertiary w-12 h-full flex flex-col justify-center items-center cursor-pointer" @click="isOpen=!isOpen" key="handle">
            
            
            <div class="text-white text-center uppercase txt-shadow font-bold text-xl flex justify-end items-center gap-4 px-4" style="transform:rotate(270deg);text-orientation;width:250px;">
                <span>Accès&nbsp;rapide</span>
                <div style="transform:rotate(90deg)">
                    <div>
                        <svg class="w-8 h-8" v-show="isOpen" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM7 9a1 1 0 000 2h6a1 1 0 100-2H7z" clip-rule="evenodd"></path></svg>
                        <svg class="w-8 h-8" v-show="!isOpen" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-11a1 1 0 10-2 0v2H7a1 1 0 100 2h2v2a1 1 0 102 0v-2h2a1 1 0 100-2h-2V7z" clip-rule="evenodd"></path></svg>
                    </div>
                </div>
            </div>
            
        </div>



            <div class="p-4 bg-white" style="width:350px;"  key="content">
                <div class="h-full flex items-center">
                <slot></slot>
                </div>
            </div>
        

       
    </div>


</div>
</template>

<script>
export default {
    data(){
        return {
            isOpen:false
        }
    },
   
}
</script>

<style>

</style>