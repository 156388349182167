<template>
    <transition-group name='fade' tag='div' class="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
  
      <img 
        class="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"         
        :src="currentImage"
        v-for="number in [currentNumber]"
        :key='number'
    />
  </transition-group>
</template>

<script>
export default {
    data(){
        return {
            images: [
                '/assets/img/temp/1.png', 
                '/assets/img/temp/8.png', 
                '/assets/img/temp/2.png', 
                '/assets/img/temp/3.png', 
                '/assets/img/temp/7.png', 
                '/assets/img/temp/4.png', 
                '/assets/img/temp/5.png',                 
                
                
                
            ],
            currentNumber: 0,
            timer: null
        }
    },

    mounted: function () {
        this.startRotation();
    },

    methods: {
        startRotation: function() {
            this.timer = setInterval(this.next, 4000);
        },

        stopRotation: function() {
            clearTimeout(this.timer);
            this.timer = null;
        },

        next: function() {
            this.currentNumber += 1
        },
        prev: function() {
            this.currentNumber -= 1
        }
    },
    
    computed: {
    	currentImage: function() {
      	return this.images[Math.abs(this.currentNumber) % this.images.length];
      }
    }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
  transition: all 2s ease;
  overflow: hidden;
  visibility: visible;
  opacity: 1;
  position: absolute;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  visibility: hidden;
}
</style>