<template>
<div>
  <div class="w-full grid grid-cols-2 relative">

    <div class="bg-gris flex col-span-2 lg:col-span-1">
      <div class="w-full overflow-y-auto" style="max-height: calc(100vh - 128px);height: calc(100vh - 128px);">


        <div v-if="organismes">
          <div v-for="letter in organismes" :key="letter.letter">
            <div class="sticky top-0 bg-primary px-4 py-1 font-semibold text-white shadow">{{ letter.letter }}</div>
            <div class="p-4">
              <div v-for="contact in letter.contacts" :key="contact.id">
                <div class="p-4 shadow rounded mb-4 cursor-pointer" :class="selected == contact.id ? 'border-primary border-2 bg-secondary': 'bprder border-gray-400 bg-white '" @click="select(contact.id)">
                  <div><strong>{{ contact.title }}</strong></div>
                  <div class="text-sm">{{ contact.responsable }} &bull; {{ contact.telephone }}</div>
                </div>
              </div>
            </div>
          </div>



        </div>
        <div v-else-if="loadingLeft">
          Chargement en cours...
        </div>


      </div>
    </div>
    <div class="bg-blanc col-span-2 lg:col-span-1 hidden lg:flex">
      <div class="overflow-y-auto p-4 w-full">

        <div v-if="currentOrganisme" class="w-full overflow-y-auto" style="max-height: calc(100vh - 128px);height: calc(100vh - 128px);">
          <h3 class="text-primary text-2xl font-bold mb-8">{{ currentOrganisme.title }}</h3>

          <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl class="sm:divide-y sm:divide-gray-200">
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.responsable">
                <dt class="text-sm font-medium text-gray-500">Responsable</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ currentOrganisme.responsable }}</dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.telephone">
                <dt class="text-sm font-medium text-gray-500">Téléphone</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ currentOrganisme.telephone }}</dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.courriel">
                <dt class="text-sm font-medium text-gray-500">Courriel</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><a :href="'mailto:'+currentOrganisme.courriel">{{ currentOrganisme.courriel }}</a></dd>
              </div>
              <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.adresse.address">
                <dt class="text-sm font-medium text-gray-500">Adresse</dt>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" v-if="currentOrganisme.adresse.parts.street_number">{{ currentOrganisme.adresse.address.replace(', Canada', '') }}</dd>
                <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" v-else>{{ currentOrganisme.adresse.address.replace(', Canada', '') }}, {{ currentOrganisme.adresse.parts.locality }}, QC {{ currentOrganisme.adresse.parts.postal_code}}</dd>
              </div>
            </dl>
          </div>

          <div v-if="currentOrganisme.contenu" style="max-height: calc(100vh - 128px);height: calc(100vh - 128px);">
              <div class="prose max-w-none" v-html="currentOrganisme.contenu"></div>
          </div>



        </div>
        <div v-else-if="loadingRight" style="max-height: calc(100vh - 128px);height: calc(100vh - 128px);">
          Chargement en cours...
        </div>
        <div v-else style="max-height: calc(100vh - 128px);height: calc(100vh - 128px);" class="prose max-w-none">
          <slot></slot>
        </div>
      </div>
    </div>





  </div>

  <!-- This example requires Tailwind CSS v2.0+ -->
  <div class="fixed z-10 inset-0 overflow-y-auto lg:hidden" aria-labelledby="modal-title" role="dialog" aria-modal="true" v-show="currentOrganisme">
    <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
      <!--
        Background overlay, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0"
          To: "opacity-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100"
          To: "opacity-0"
      -->
      <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100"
          leave-to-class="opacity-0"
      >
        <div @click="select(-1)" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" v-show="currentOrganisme"></div>
      </transition>

      <!-- This element is to trick the browser into centering the modal contents. -->
      <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

      <!--
        Modal panel, show/hide based on modal state.

        Entering: "ease-out duration-300"
          From: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          To: "opacity-100 translate-y-0 sm:scale-100"
        Leaving: "ease-in duration-200"
          From: "opacity-100 translate-y-0 sm:scale-100"
          To: "opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      -->
      <transition
          enter-active-class="ease-out duration-300"
          enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to-class="opacity-100 translate-y-0 sm:scale-100"
          leave-active-class="ease-in duration-200"
          leave-class="opacity-100 translate-y-0 sm:scale-100"
          leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      >
      <div v-show="currentOrganisme" class="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
        <div class=" absolute top-0 right-0 pt-4 pr-4">
          <button @click="select(-1)" type="button" class="bg-danger rounded-md text-white hover:text-red-100 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="sr-only">Close</span>
            <!-- Heroicon name: outline/x -->
            <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="lg:hidden w-full" style="height:75vh">
          <div class="overflow-y-auto p-4 w-full">

            <div v-if="currentOrganisme" class="w-full" style="max-height:72vh">
              <h3 class="text-primary text-xl font-bold mb-2">{{ currentOrganisme.title }}</h3>

              <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
                <dl class="sm:divide-y sm:divide-gray-200">
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.responsable">
                    <dt class="text-sm font-medium text-gray-500">Responsable</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ currentOrganisme.responsable }}</dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.telephone">
                    <dt class="text-sm font-medium text-gray-500">Téléphone</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{{ currentOrganisme.telephone }}</dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.courriel">
                    <dt class="text-sm font-medium text-gray-500">Courriel</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><a :href="'mailto:'+currentOrganisme.courriel">{{ currentOrganisme.courriel }}</a></dd>
                  </div>
                  <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4" v-if="currentOrganisme.adresse.address">
                    <dt class="text-sm font-medium text-gray-500">Adresse</dt>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" v-if="currentOrganisme.adresse.parts.street_number">{{ currentOrganisme.adresse.address.replace(', Canada', '') }}</dd>
                    <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2" v-else>{{ currentOrganisme.adresse.address.replace(', Canada', '') }}, {{ currentOrganisme.adresse.parts.locality }}, QC {{ currentOrganisme.adresse.parts.postal_code}}</dd>
                  </div>
                </dl>
              </div>

              <div v-if="currentOrganisme.contenu" style="max-height: calc(100vh - 128px);height: calc(100vh - 128px);">
                <div class="prose prose-sm max-w-none" v-html="currentOrganisme.contenu"></div>
              </div>



            </div>
            <div v-else-if="loadingRight" style="max-height: calc(100vh - 128px);height: calc(100vh - 128px);">
              Chargement en cours...
            </div>
            <div v-else>
              Sélectionnez un organisme pour obtenir plus d'information.
            </div>
          </div>
        </div>
      </div>
      </transition>
    </div>
  </div>




  </div>

</template>

<script>

const axios = require('axios');
import _ from 'lodash';

export default {
  name: "Organismes",
  data(){
    return {
      organismes: [],
      selected: null,
      currentOrganisme: null,
      loadingLeft:false,
      loadingRight:false,
    }
  },
  methods:{
    select(id){
      if ((id == this.selected) || (id==-1)){
        this.selected=null;
        this.currentOrganisme = null;
      }
      else{
        this.selected=id;

        let data = {
          CRAFT_CSRF_TOKEN : window.csrfToken,
          id: id,
        };

        this.loadingRight=true;

        axios.post('/actions/malartic-module/common/get-organisme', data)
            .then((response) => {
                this.currentOrganisme = response.data;
            })
        .finally(() =>{
          this.loadingRight=false;
        });


      }
    },
    getOrganismes(){

      let data = {
        CRAFT_CSRF_TOKEN : window.csrfToken,
      };

      this.loadingLeft=true;

      axios.post('/actions/malartic-module/common/get-organismes', data)
          .then((response) => {

            let organismes = response.data;
            this.organismes = _(organismes)
                .groupBy('title[0]')
                .map((contacts, letter) => ({ letter, contacts }))
                .value();
          })
          .finally(() => {
            this.loadingLeft=false;
          });
    }
  },
  mounted(){
    this.getOrganismes();
  }
}
</script>

<style scoped>

</style>