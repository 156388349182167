<template>
  <div class="">
        <div class="tab__header">
            <a href="#" class="tab__link py-2 block no-underline text-lg leading-tight md: text-2xl lg:text-3xl text-primary hover:text-tertiary flex justify-between" @click.prevent="active = !active">
                <strong>{{title}}</strong>
                <span class="down-Arrow transform duration-300" :class="active?'-rotate-180':''"><i class="fas fa-caret-down"></i></span>                
            </a>
        </div>
        <div class="border-b-2 border-gris">
            <collapse-transition>
            <div class="tab__content py-2" v-show="active"><slot /></div>          
            </collapse-transition>          
        </div>
    </div>
</template>

<script>
  import { CollapseTransition } from "@ivanv/vue-collapse-transition"

export default {
    props: {
        title: String,
    },
    components: {
      CollapseTransition,
    },
    data(){
        return {
            active: false,
        }
    }
}
</script>

<style>

</style>