<template>
  <div  @click="popMap()">
      <span class="font-bold text-primary hover:text-secondary transform duration-200 cursor-pointer">{{ vente.adresse }}</span><br />
      <small>{{ vente.dates }}</small>
  </div>
</template>

<script>

import { EventBus } from '@/event-bus';

export default {
    props: {
        vente: Object
    },
    methods:{
        popMap(){            
            EventBus.$emit('popmap', this.vente)
        }
    }
}
</script>

<style>

</style> 