<template>
  <div>
   

    <div class="flex items-center space-x-1 lg:space-x-2">

    <div class="hidden lg:block font-thin text-md mr-4"><slot></slot></div>

        <ShareNetwork
            network="facebook"
            :url="url"
            :title="uriencode(title)"
            :description="uriencode(description)"                   
            hashtags="malartic"
        >
            <span class="sr-only">Facebook</span>           
            <div class="text-white bg-primary hover:bg-tertiary rounded-full p-1 flex items-center justify-center">                     
              <div class="h-5 w-5 text-center flex items-center justify-center">    
                <i class="fab fa-facebook-f"></i>
              </div>
            </div>      
         </ShareNetwork>

         <ShareNetwork
            network="twitter"
            :url="url"
            :title="uriencode(title)"
            :description="uriencode(description)"                 
            hashtags="malartic"
        >
            <span class="sr-only">Twitter</span>        
            <div class="text-white bg-primary hover:bg-tertiary rounded-full p-1 flex items-center justify-center">              
              <div class="h-5 w-5 text-center flex items-center justify-center">              
                <i class="fab fa-twitter"></i>
              </div>
            </div>      
         </ShareNetwork>

         <ShareNetwork
            network="linkedin"
            :url="url"
            :title="uriencode(title)"
            :description="uriencode(description)"                  
            hashtags="malartic"
        >
            <span class="sr-only">LinkedIn</span>        
            <div class="text-white bg-primary hover:bg-tertiary rounded-full p-1 flex items-center justify-center">
              <div class="h-5 w-5 text-center flex items-center justify-center">              
                <i class="fab fa-linkedin-in"></i>
              </div>
            </div>      
         </ShareNetwork>

         <ShareNetwork
            network="email"
            :url="url"
            :title="uriencode(title)"
            :description="uriencode(description)"            
            hashtags="malartic"
        >
            <span class="sr-only">Courriel</span>        
            <div class="text-white bg-primary hover:bg-tertiary rounded-full p-1 flex items-center justify-center">
              <div class="h-5 w-5 text-center flex items-center justify-center">              
                <i class="fas fa-envelope"></i>
              </div>
            </div>      
         </ShareNetwork>

         
    </div>




  </div>
</template>

<script>


export default {
    props: {
        url: String,
        description: String,
        title: String,
    },
    components: {
       
    },
    methods:{
      uriencode(val){
        return encodeURIComponent(val);
      }
    },
    mounted(){
      console.log(this.description)
    }
}
</script>

<style>

</style>