<template>
  <div>    

       <div id="map" ref="Map"  class="w-full h-map"></div>
     
  </div>
</template>

<script>

import { EventBus } from '@/event-bus';

const MAP_MARKER = 'M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z';


export default {
  props: {
    attraits: Array
  },
  
  data() {
    return {      
        map: null,
        allMarkers: {},
        allInfos: {},
        options: {
            mapId:'6ca00a11ee05ef42',
            //disableDefaultUI: true,
            center: {
              lat: 48.13831, 
              lng: -78.12362
            },
            zoom: 15,
        }
    }
  },

  methods: {
    toggleBounce(id) {

      this.attraits.forEach(marker => {
        this.allMarkers[marker.id].setAnimation(null);
      })
      this.allMarkers[id].setAnimation(google.maps.Animation.BOUNCE);      
    },
    openWindow(id){

      var that=this;

      this.attraits.forEach(marker => {
        this.allInfos[marker.id].close();
      })
      this.allInfos[id].open({
        anchor: this.allMarkers[id],
        map,
        shouldFocus: false,
      });

      this.map.panTo(this.allInfos[id].position)

    }
  },

  mounted(){
    this.map = new google.maps.Map(document.getElementById('map'), this.options);

  

    this.attraits.forEach(marker => {
      this.allMarkers[marker.id] = new google.maps.Marker({
        position: marker.position,
        //label: (marker.number).toString(),
        map: this.map,
        icon: {
    path: MAP_MARKER,
    scale: 2, // > 1 to make it bigger
    fillColor: '#0079b4', // Use RGBa function
    fillOpacity: 1,
    strokeColor: '#69bee8', // Use HSLa function
    anchor: { x: 12, y: 24 },
  },
        animation: google.maps.Animation.DROP,
      });

      var _content = '<h1 class="text-lg font-bold text-primary">'+marker.nom+'</h1>'+
      '<p>'+ marker.adresse+'</p>';

      this.allInfos[marker.id] = new google.maps.InfoWindow({
        content: _content,
      });

      this.allMarkers[marker.id].addListener("click", () => {
        this.attraits.forEach(marker => {
          this.allInfos[marker.id].close();
        })
        this.allInfos[marker.id].open({
          anchor: this.allMarkers[marker.id],
          map,
          shouldFocus: false,
        });
      });



    });

    EventBus.$on('popmap', (attrait) => {            
          //this.toggleBounce(attrait.id);
          this.openWindow(attrait.id);
    });
   
  },

  
    beforeDestroy(){
        EventBus.$off('popmap');
    }
}
</script>

<style>

</style>