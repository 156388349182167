<template>
  <div>
      <div class="relative sm:flex p-4 bg-gris border border-gray-400 shadow-lg">
                  <div class="mb-4 flex-shrink-0 sm:mb-0 sm:mr-4">
                    <div @click="popMap()" class="cursor-pointer h-48 w-64 w-full sm:w-64 bg-tertiary text-gray-300  rounded-lg" preserveAspectRatio="none" stroke="currentColor" fill="none" viewBox="0 0 200 200" aria-hidden="true">
                      <img :src="attrait.photo ? attrait.photo : '/assets/img/logo-malartic-blanc-seul.png'" class="object-cover object-center h-48 w-64 w-full sm:w-64 rounded-lg"/>
                    </div>

                    <div class="flex mt-2 justify-start space-x-1">

                      
                      <a v-if="attrait.facebook" :href="attrait.facebook" target="_blank" class="text-white bg-tertiary transform duration-200 hover:bg-secondary rounded-full p-1">
                          <span class="sr-only">Facebook</span>        
                          <div class="h-5 w-5 text-center">              
                            <i class="fab fa-facebook-f"></i>
                          </div>
                        </a>  
                      
                      <a v-if="attrait.instagram" :href="attrait.instagram" target="_blank" class="text-white bg-tertiary transform duration-200 hover:bg-secondary rounded-full p-1">
                        <span class="sr-only">Instagram</span>                
                        <div class="h-5 w-5 text-center">              
                            <i class="fab fa-instagram"></i>
                          </div>
                      </a>      
                      
                      <a v-if="attrait.twitter" :href="attrait.twitter" target="_blank" class="text-white bg-tertiary transform duration-200 hover:bg-secondary rounded-full p-1">
                        <span class="sr-only">Twitter</span>                
                        <div class="h-5 w-5 text-center">              
                            <i class="fab fa-twitter"></i>
                          </div>
                      </a>      
                      
                      <a v-if="attrait.youtube" :href="attrait.youtube" target="_blank" class="text-white bg-tertiary transform duration-200 hover:bg-secondary rounded-full p-1">
                        <span class="sr-only">YouTube</span>                
                        <div class="h-5 w-5 text-center">              
                            <i class="fab fa-youtube"></i>
                          </div>
                      </a>      
                      
                      <a v-if="attrait.linkedin" :href="attrait.linkedin" target="_blank" class="text-white bg-tertiary transform duration-200 hover:bg-secondary rounded-full p-1">
                        <span class="sr-only">LinkedIn</span>                
                        <div class="h-5 w-5 text-center">              
                            <i class="fab fa-linkedin-in"></i>
                          </div>
                      </a>
                      

                    </div>


                  </div>
                  <div class="flex-grow">
                    <h4 class="text-lg font-bold cursor-pointer" @click="popMap()">{{ attrait.nom }}</h4>
                    <div class="mt-1 leading-tight stylelists attrait-contenu w-full" v-html="attrait.contenu"></div>

                    <div class="mt-4 space-y-2">

                      
                      <div class="flex" v-if="attrait.horaire">
                        <!-- Heroicon name: clock -->                                              
                        <svg class="flex-shrink-0 -mt-0.5 h-6 w-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
                        <div class="ml-3 text-sm">
                          <p class="font-medium text-blue-gray-900">{{ attrait.horaire }}</p>                        
                        </div>
                      </div>
                      
                    
                      
                      <div class="flex" v-if="attrait.adresse && !noAddress">
                        <!-- Heroicon name: location-marker -->                      
                        <svg class="flex-shrink-0 -mt-0.5 h-6 w-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                        <div class="ml-3 text-sm">
                          <p class="font-medium text-blue-gray-900">{{ attrait.adresse }}</p>                        
                        </div>
                      </div>
                      

                      
                      <div class="flex" v-if="attrait.telephone">
                        <!-- Heroicon name: phone -->                                              
                        <svg class="flex-shrink-0 -mt-0.5 h-6 w-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"></path></svg>
                        <div class="ml-3 text-sm">
                          <p class="font-medium text-blue-gray-900">{{ attrait.telephone }}</p>                        
                        </div>
                      </div>
                      
                      <div class="flex" v-if="attrait.courriel">
                        <!-- Heroicon name: mail -->                                                                      
                        <svg class="flex-shrink-0 -mt-0.5 h-6 w-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"></path></svg>
                        <div class="ml-3 text-sm">
                          <p class="font-medium text-blue-gray-900"><a class="text-tertiary hover:text-secondary transform duration-200" :href="'mailto:'+attrait.courriel">{{ attrait.courriel }}</a></p>
                        </div>
                      </div>
                      

                      
                      <div class="flex" v-if="attrait.lien">
                        <!-- Heroicon name: mail -->                                                                                              
                        <svg class="flex-shrink-0 -mt-0.5 h-6 w-6 text-primary" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9"></path></svg>
                        <div class="ml-3 text-sm">
                          <p class="font-medium text-blue-gray-900"><a class="text-tertiary hover:text-secondary transform duration-200" :href="attrait.lien" target="_blank">{{ attrait.lien_clean }}</a></p>                 
                        </div>
                      </div>
                      

                    </div>

                  </div>

                  <div @click="popMap()" class="cursor-pointer absolute h-12 w-12 flex justify-center items-center bottom-0 right-0 bg-secondary hover:bg-tertiary text-white transition duration-500">                        
                        <svg class="w-8 h-8" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"></path><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"></path></svg>
                    </div>
                </div>
  </div>
</template>

<script>

import { EventBus } from '@/event-bus';

export default {
    props: {
        attrait: Object,
        noAddress: {
          type: Boolean,
          default: false,
        },        
    },
    methods:{
        popMap(){            
            EventBus.$emit('popmap', this.attrait)
        }
    }
}
</script>

<style>

</style>