<template>

    <a :href="href" :target="target ? target : '_self'" :class="'group transition duration-200 rounded-full py-2 px-2 flex justify-between items-center text font-bold focus:outline-none uppercase '+col1" :style="'width:'+(width?width:'300')+'px'">
            <div class="px-4"><slot></slot></div>
            <div :class="'h-8 w-8 rounded-full flex items-center justify-center '+col2">
              <i class="fas fa-arrow-right"></i>
            </div>
          </a>

</template>

<script>
export default {
    props: {
        href: String,
        target: String,
        color: String,
        width: String,
    },
    data(){
        return {
            col1: 'text-white bg-tertiary',            
            col2: 'text-tertiary bg-white',            
        }
    },
    mounted(){
        if (this.color=='blue'){
            this.col1 = 'text-white bg-tertiary hover:bg-primary';
            this.col2 = 'text-tertiary bg-white';
        }
        if (this.color=='white'){
            this.col1 = 'text-tertiary bg-white hover:bg-blue-100';
            this.col2 = 'text-white bg-tertiary';
        }
        if (this.color=='red'){
            this.col1 = 'text-danger bg-white hover:bg-red-100';
            this.col2 = 'text-white bg-danger';
        }
    },
    
}
</script>

<style>

</style>