<template>
	<transition name="fade">
		<div v-if="show" class="preloader">
			<div class="logo">
                <img src="/assets/img/logo-malartic.png" />
            </div>
			<!-- <p style="color:#005F7C;font-weight:bold;">Chargement en cours. Veuillez patienter...</p>		-->
            <div class="lds-facebook" style="width:100px"><div></div><div></div><div></div></div>	
		</div>
	</transition>
</template>

<script>
export default {
	name: 'preloader',
	data(){
		return {
			show: true
		}
	},
	mounted(){
		if(Boolean(this.show)) this.showToggle()
	},
	methods: {
		showToggle(){
			setTimeout(() => {
				this.show = false
			}, 700)
		}
	}
}
</script>

<style>

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #005F7C;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 8px;
    height: 64px;
  }
  50%, 100% {
    top: 24px;
    height: 32px;
  }
}


.preloader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: 9999;
  font-family:Arial;
}
.preloader .logo {
  width: 32.5rem;  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
}
.preloader p {
  font: 600 1.5rem #333;
  margin-top: 1rem;
}

</style>