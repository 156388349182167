<template>
    <div class="flex flex-col h-full relative">
        <div class="bg-primary flex justify-between items-center text-white w-full h-16 flex-none">
            <button :disabled="loading" @click="prev" class="h-16 w-16 flex items-center justify-center cursor-pointer bg-transparent hover:bg-tertiary" :class="loading?'opacity-50':''">
                <i class="fas fa-chevron-left"></i>
            </button>
            <div class="capitalize font-bold text-lg">{{currentDate | moment('MMMM YYYY')}}</div>
            <button :disabled="loading" @click="next" class="h-16 w-16 flex items-center justify-center cursor-pointer bg-transparent hover:bg-tertiary" :class="loading?'opacity-50':''">
                <i class="fas fa-chevron-right cursor-pointer" ></i>
            </button>
        </div>
        <div class="flex-grow">
            <div class="absolute bottom-0 right-0 mb-1 mr-1 p-1">
              <slot></slot>
            </div>
            <div v-if="calendar">
                <div v-html="calendar" :class="loading?'filter blur animate-pulse transition duration-200':''"></div>
            </div>
            <transition
            enter-active-class="transition ease-out duration-200 transform"
            enter-class="opacity-0 scale-50"
            enter-to-class="opacity-100 scale-100"
            leave-active-class="transition ease-in duration-200 transform"
            leave-class="opacity-100 scale-100"
            leave-to-class="opacity-0 scale-50"
            >
            <div v-if="loading" class="h-full w-full absolute top-0 left-0 bottom-0 right-0 flex justify-center items-center">
                <span class="bg-primary text-white border border-secondary p-1 text-sm shadow-lg p-4 rounded-full">
                    <div class="w-full flex items-center justify-center">
                        <svg class="animate-spin h-8 w-8 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        
                    </div>
                </span>
            </div>
            </transition>
        </div>
    </div>
</template>

<script>
import moment from 'moment'
const axios = require('axios');

export default {
    data(){
        return {
            loading:false,
            currentDate:null,
            calendar: '',
        }
    },
    methods:{
        prev(){
           this.currentDate = moment(this.currentDate).subtract(1, 'months');           
           this.getCalendar();
        },
        next(){
            this.currentDate = moment(this.currentDate).add(1, 'months');            
            this.getCalendar();
        },
        getCalendar(){

            this.loading=true;

            var data = {
                'currentDate' : moment(this.currentDate).toISOString()
            }

             axios.post('/cal', data)
            .then((response) => {
                this.calendar=response.data
                this.loading=false;
            });
        }
    },
    mounted(){
        var d = new Date();
        this.currentDate = moment(d).clone().startOf('month');
        this.getCalendar();
        
    },
    filters: {
        month: function (date) {
            return moment(date).format('MMMM');
        },
        year: function (date) {
            return moment(date).format('YYYY');
        }
    }
}
</script>

<style>

</style>