<template>
  <li>
      <a :href="url" class="block transition duration-150 ease-in-out hover:bg-gris">
        <div class="flex items-center py-2">
          <div class="min-w-0 flex-1 flex items-center">
            
            <div class="min-w-0 flex-1 px-2 md:grid md:grid-cols-2 md:gap-4">
              <div>
                  <div class="flex items-center text-sm leading-5 text-gray-500 uppercase" v-if="surtitre">                  
                  <span class="truncate">{{ surtitre }}</span>
                </div>
                <div class="text-lg font-bold leading-5 font-medium text-primary truncate">{{ title }}</div>                
              </div>
              
            </div>
          </div>
          <div>
            <!-- Heroicon name: chevron-right -->
            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
              <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
            </svg>
          </div>
        </div>
      </a>
    </li>
</template>

<script>
export default {
    props: {
        'title': String,
        'surtitre': String,
        'date': String,
        'url' : String
    }
}
</script>

<style>

</style>