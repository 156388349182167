<template>
  <div class="pb-4">
    <div class="flex items-center justify-between space-x-2">
        <div class="text-primary font-bold text-lg" v-html="edifice.nom"></div>
        <div v-if="edifice.afficher || edifice.info">
            <button @click="open()" type="button" class="inline-flex items-center p-0 border border-transparent rounded-full shadow-sm text-white bg-tertiary hover:bg-secondary focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary">
                <!-- Heroicon name: solid/plus -->
                <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                    <path fill-rule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clip-rule="evenodd" />
                </svg>
            </button>
        </div>
    </div>
    <div v-if="edifice.adresse" v-html="edifice.adresse"></div>
    <div v-if="edifice.telephone" v-html="edifice.telephone"></div>
    <div v-if="edifice.courriel"><a class="text-tertiary hover:text-secondary transform duration-200" :href="'mailto:'+ edifice.courriel ">{{ edifice.courriel }}</a></div>

    <div v-if="edifice.afficher || edifice.info">
        

        <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-out duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
<div class="fixed z-10 inset-0 overflow-y-auto" v-show="isOpen">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-out duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
    <div v-show="isOpen" class="fixed inset-0 transition-opacity" @click="isOpen=false">
      <div class="absolute inset-0 bg-tertiary opacity-75"></div>
    </div>
    </transition>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="ease-out duration-200"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
    <div v-show="isOpen" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div>       
        <div class="mt-3 sm:mt-5">
          <h3 class="text-2xl leading-6 font-bold text-primary text-center uppercase mb-8" id="modal-headline">
            {{ edifice.nom }}
          </h3>
          <div class="mt-2">
            <slot></slot>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 text-center">
        <span class="flex w-full rounded-md shadow-sm">
          <button type="button" @click="isOpen=false" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary text-base leading-6 font-medium text-white shadow-sm hover:bg-tertiary focus:outline-none focus:border-primary focus:shadow-outline-primary transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Fermer
          </button>
        </span>
      </div>
    </div>
    </transition>
  </div>
</div>
</transition>

    </div>


</div>
</template>

<script>
export default {
    props: {
        edifice: Object
    },
    data(){
        return{
            isOpen:false
        }
    },
    methods: {
        open(){
            this.isOpen=true;
        }
    }
}
</script>

<style>

</style>