<template>

    <div>

        <div class="w-full bg-tertiary p-4 flex justify-center text-white cursor-pointer hvr-grow-shadow" @click="open()">            
            <div class="flex">
            <div class="mr-4 flex-shrink-0 self-center">
                <img src="/assets/img/ajoutagenda.png" alt="Calendrier" />
            </div>
            <div class="leading-none">
                <div class="font-thin text-xl">Ajouter à</div>
                <div class="font-bold uppercase text-4xl">Mon agenda</div>
            </div>
            </div>
        </div>

<!--
  Tailwind UI components require Tailwind CSS v1.8 and the @tailwindcss/ui plugin.
  Read the documentation to get started: https://tailwindui.com/documentation
-->
<transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-out duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
<div class="fixed z-10 inset-0 overflow-y-auto" v-show="isOpen">
  <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
    <!--
      Background overlay, show/hide based on modal state.

      Entering: "ease-out duration-300"
        From: "opacity-0"
        To: "opacity-100"
      Leaving: "ease-in duration-200"
        From: "opacity-100"
        To: "opacity-0"
    -->
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-active-class="ease-out duration-200"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
    >
    <div v-show="isOpen" class="fixed inset-0 transition-opacity" @click="isOpen=false">
      <div class="absolute inset-0 bg-tertiary opacity-75"></div>
    </div>
    </transition>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span class="hidden sm:inline-block sm:align-middle sm:h-screen"></span>&#8203;
    <transition
      enter-active-class="ease-out duration-300"
      enter-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enter-to-class="opacity-100 translate-y-0 sm:scale-100"
      leave-active-class="ease-out duration-200"
      leave-class="opacity-100 translate-y-0 sm:scale-100"
      leave-to-class="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
    <div v-show="isOpen" class="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6" role="dialog" aria-modal="true" aria-labelledby="modal-headline">
      <div>       
        <div class="mt-3 sm:mt-5">
          <h3 class="text-2xl leading-6 font-bold text-primary text-center uppercase mb-8" id="modal-headline">
            Ajouter à mon agenda
          </h3>
          <div class="mt-2">
            <slot></slot>
          </div>
        </div>
      </div>
      <div class="mt-5 sm:mt-6 text-center">
        <span class="flex w-full rounded-md shadow-sm">
          <button type="button" @click="isOpen=false" class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-primary text-base leading-6 font-medium text-white shadow-sm hover:bg-tertiary focus:outline-none focus:border-primary focus:shadow-outline-primary transition ease-in-out duration-150 sm:text-sm sm:leading-5">
            Annuler
          </button>
        </span>
      </div>
    </div>
    </transition>
  </div>
</div>
</transition>

    </div>
</template>

<script>
export default {
    props: {
        event: Object,        
    },
    data(){
        return{
            isOpen:false
        }
    },
    methods: {
        open(){
            this.isOpen=true;
        }
    }
}
</script>

<style>

</style>