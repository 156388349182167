<template>
    <agile :initial-slide="1">
        <div class="w-full slide bg-cover bg-center relative" v-for="image in images" :key="image.id" :style="'background-image:url(\''+image.url+'\')'">
            <div class="absolute w-full flex justify-center" v-if="image.title">
                <div class="mt-2 mx-auto bg-primary py-1 px-2 text-white text-sm font-bold">{{ image.title }}</div>
            </div>
        </div>        
    <template slot="prevButton"><i class="fas fa-chevron-left"></i></template>
    <template slot="nextButton"><i class="fas fa-chevron-right"></i></template>
  </agile>
</template>

<script>

import { VueAgile } from 'vue-agile'

export default {
   components: {
       agile: VueAgile 
   },

   props: {
       images: Array,       
   },

   data: () => ({
     
      
   }),
}
</script>

<style>
.agile__nav-button {
  background: transparent;
  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 24px;
  height: 100%;
  position: absolute;
  top: 0;
  transition-duration: 0.3s;
  width: 80px;
}
.agile__nav-button:hover {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 1;
}
.agile__nav-button--prev {
  left: 0;
}
.agile__nav-button--next {
  right: 0;
}
.agile__dots {
  bottom: 10px;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
}
.agile__dot {
  margin: 0 10px;
}
.agile__dot button {
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 10px;
  font-size: 0;
  line-height: 0;
  margin: 0;
  padding: 0;
  transition-duration: 0.3s;
  width: 10px;
}
.agile__dot--current button, .agile__dot:hover button {
  background-color: #fff;
}

.slide {  
  display: block;
  height: 500px;
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  position:relative;
}
</style>