<template>

    <div class="bg-tertiary">
        <a 
        :href="href" 
        :target="target ? target : '_self'" 
        class="relative bg-tertiary w-full flex items-center justify-center h-48  bg-center bg-cover" 
        :style="image ? ('background-image:url(\'' + image + '\');filter: grayscale(100%) contrast(200%);opacity: 1;mix-blend-mode: screen;') : ''">
            <div class="absolute w-full h-full bg-tertiary" style="opacity: 0.75;"></div>                
            <div class="absolute w-full h-full flex items-center justify-center p-12">
                <div class="text-white uppercase font-bold text-2xl leading-none text-center" style="filter: drop-shadow(2px 2px 1px black);">
                    <slot></slot>
                </div>
            </div>
        </a>
    </div>

</template>

<script>
export default {
    props: {        
        href: String,
        target: String,      
        image: String,  
    },
}
</script>

<style>

</style>